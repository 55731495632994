import { initializeApp, getApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCvUVb-kzWp9EVhiLB9cBZrOG2v8kpESWc",
  authDomain: "anunciando-web.firebaseapp.com",
  databaseURL: "https://anunciando-web-default-rtdb.firebaseio.com",
  projectId: "anunciando-web",
  storageBucket: "anunciando-web.appspot.com",
  messagingSenderId: "900680063473",
  appId: "1:900680063473:web:880e18b604051ea7fb4c50",
};

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);

const firebaseApp = getApp();
export const storage = getStorage(
  firebaseApp,
  "gs://anunciando-web.appspot.com"
);
